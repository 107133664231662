var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon,
      },
    },
    [
      _c(
        "asyent-form",
        {
          ref: _vm.formRefAE,
          attrs: {
            "data-error": _vm.dataError,
            "data-loading": _vm.dataLoading,
            errors: _vm.errors,
            "form-action-method": _vm.formActionMethod,
            "form-actions": _vm.formActions,
            "form-rop": _vm.rop,
            "is-form-submitted": _vm.formSubmitted,
            "is-form-submitting": _vm.formSubmitting,
            readonly: _vm.isReadonly,
            reference: _vm.formRef,
            success: _vm.success,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "asyent-card",
                            { attrs: { "no-maximize": "", title: "Header" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4", lg: "4" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value: _vm.formDataF["eventId"],
                                          label: "ID",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4", lg: "4" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value: _vm.formDataF["eventTypeCode"],
                                          label: "Type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4", lg: "4" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value:
                                            _vm.formDataF[
                                              "eventOccurrenceTime"
                                            ],
                                          label: "Occurrence Time",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4", lg: "4" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value: _vm.formDataF["locationCode"],
                                          label: "Location",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4", lg: "4" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value: _vm.formDataF["providerName"],
                                          label: "Provider",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4", lg: "4" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value:
                                            _vm.formDataF["originatorName"],
                                          label: "Originator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "asyent-card",
                            { attrs: { "no-maximize": "", title: "Data" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("VueJsonPretty", {
                                        attrs: {
                                          path: "res",
                                          data: _vm.formDataF["eventData"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.formValid,
            callback: function ($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid",
          },
        },
        [_vm._v("2 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }